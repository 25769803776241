.container-contact {
  width: 100%;
  height: 130vh;
  backdrop-filter: grayscale(100%);
  background-image: url('../../assets/images/textura3.jpg');
    background-size: cover;
  .title-contact {
    font-family: "Andale Mono Regular", sans-serif;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    h1{
      color: white;
    }
  }
  img{
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {

      object-fit: fill;
     }
  }
  .text_contact{
    text-align: center;
    padding: 5%;
    font-family: 'Andale Mono Regular', sans-serif;
    padding: 3% 25%;
  @media (max-width: 900px) {
    padding: 3% 5%;
  }

  }
  .social-networks{
    margin-top: 3%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .networks{
        display: grid;
        margin: 0px 3%;
        grid-template-columns: auto auto auto auto auto;
        justify-content: center;
    align-items: center;
    justify-items: center;
        img{
            width: 45%;
        }
        @media (max-width: 560px) {
            grid-template-columns: auto;
            gap: 5%;
            img{
                width: 15%;
            }
           }
    }
  }
  @media (max-width: 560px) {
height: 80vh;
   }
   @media (max-width: 460px) {
    height: 100vh;
       }
}
span{
  color: white;
  font-size: 18px;
  text-decoration: underline;
}