.navbar {
 
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../../assets/images/fondo.jpg");
    z-index: 99;
    // background-size: covr;
    backdrop-filter: grayscale(100%);
    filter: grayscale(100%);

   

    

    .icon-title{
        display: flex;
        align-items: center;
        gap: 10px;
        h1{
            font-size: calc(18px + 0.390625vw);
            margin-bottom: 0;
            color: rgb(255, 255, 255);
            margin-right: 30px;
            font-family: 'Teenage Dreams', sans-serif;
        
        }
      
    }
  }
  

  
  .img-logo {
    height: 80px;
  }
  
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 34px;
    list-style: none;
    text-align: center;
    width: auto;
    height: 50px;
    justify-content: end;
    margin-right: 0rem;
    font-family: 'Andale Mono Regular', sans-serif;
    align-items: center;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 70px;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #b291246e;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color:white;
}
.fa-bars {
    color: white;
  }
  
  
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .img-logo{
        height: 40px;
    }
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: gray;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 350px;
      position: absolute;
    }
  
    .nav-links {
      text-align: center;
      padding: 0px;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #020873;
      border-radius: 0;
      color:#fff
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: white;
      font-size: 2rem;
    }
  
   
  
  
  
    button {
      display: none;
    }
  }