.footer{
    bottom: 0;
    width: 100%;
    color: white;
    background-image: url('../../assets/images/grunge.jpg');
    background-position: 38% 40%;
    background-size: 130% 150%;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-footer{
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 60px;
        img{
            max-width: 25px;
            max-height: 25px;
        }

    }
    .NetworkSocialIcons{
        img{
            max-height: 40px;
            max-width: 40px;
            margin-right: 20px;
        }
    }
}