.container-all-aboutUs {
  font-family: "Andale Mono Regular", sans-serif;
  // text-shadow: black 0.2em 0.1em 0.1em;
  color: black;
  font-weight: 600;
  text-align: justify;
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/textura3.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {
      object-fit: fill;
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  margin-top: 2%;
  font-family: "Andale Mono Regular", sans-serif;

  h1 {
    color: white;
  }
}

.container_1_job {
  display: grid;
  grid-template-columns: 70% 30%;
  height: auto;
  padding: 3%;
  align-items: center;
  .container_1_text_job {
    padding: 10px 5%;
  }
  .container_img_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 300px;
      width: 300px;
      object-fit: conver;
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: auto;
  }
}
p {
  color: white;
}

.container_2_job {
  padding: 3% 25%;
  @media (max-width: 900px) {
    padding: 3% 5%;
  }
}
.container_2_photos {
  padding: 3% 5%;
}
.container_2_photos img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}
.container_2_photos img:hover {
  transform: scale(1.1); /* Zoom al hacer hover */
}
.container_3_job {
  padding: 3% 25%;
  @media (max-width: 900px) {
    padding: 3% 5%;
  }
}
.container_3_videos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3% 7%;
  gap: 16px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}
.container_3_videos_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
