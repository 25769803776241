.container-noticias {
  font-family: 'Andale Mono Regular', sans-serif;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/textura3.jpg");
  -webkit-box-shadow: 0px 0px 28px 17px #000000;
  box-shadow: 0px 0px 28px 17px #000000;
  img{
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {

      object-fit: fill;
     }
  }
}
.container_text_new{
  padding: 3% 25%;
  span{
    font-weight: 700;
    font-size: 18px;
    color: rgb(255, 255, 255);
  }
  @media (max-width: 900px) {
    padding: 3% 5%;
  }
}