.container-all-home {
  font-family: "Andale Mono Regular", sans-serif;
  // text-shadow: black 0.2em 0.1em 0.1em;
  background-image: url("../../assets/images/fondo.jpg");
  background-position: 100% 10%;
  filter: grayscale(100%);
  background-size:110% ;
  background-repeat: no-repeat;
  color: black;
  font-weight: 600;
  width: 100%;
  height: 135vh;

  img {
    object-fit: cover;
    width: 100%;
    height: 80%;
  }
  @media (max-width: 600px) {
    background-size: auto;

  }
}
.container-notes {
  font-family: "Andale Mono Regular", sans-serif;
  font-weight: 600;
  background-image: url("../../assets/images/Papel-cuadrado.png");
  background-size: 46% 80%;
  background-repeat: no-repeat;
  background-position: 50% 60%;
  height: 100vh;
  width: 100%;
  position: absolute;
  .parrafo-1 {
    position: absolute;
    top: 24%;
    width: 32%;
    left: 35%;
    font-size: calc(12px + 0.390625vw);
    z-index: 99;
    text-align: center;
    @media (max-width: 960px) {
      top: 22%;
    }
    @media (max-width: 800px) {
      top: 22%;
      width: 40%;
      left: 32%;
    }
    @media (max-width: 600px) {
      width: 50%;
      left: 28%;
    }
    @media (max-width: 400px) {
      
      top: 10%;
    }
  }

  @media (max-width: 960px) {
    background-position: 50% 40%;
  }
  // @media (max-width: 820px) {
  //   background-size: 71% 35%;

  // }
  @media (max-width: 800px) {
    background-size: 60% 72%;
  }
  // @media (max-width: 706px) {
  //   background-size: 74% 58%;
  // }
  @media (max-width: 600px) {
    background-size: 74% 100%;
  }
  @media (max-width: 540px) {
    background-size: 74% 100%;
  }
  @media (max-width: 420px) {
    background-size: 74% 100%;
  }

  @media (max-width: 600px) {
    font-size: calc(12px + 0.390625vw);
  }
}
