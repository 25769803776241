@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: "Gregs Other Hand";
  src: url("./fonts/GREGOH__.TTF");
}
@font-face {
  font-family: "Andale Mono Regular";
  src: url("./fonts/andalemo.ttf");
}
@font-face {
  font-family: "Teenage Dreams";
  src: url("./fonts/TeenageDreamsFont-Demo-Regular.ttf");
}
body {
  margin: 0;
  padding: 0;
  font-family: "Teenage Dreams", sans-serif, "Gregs Other Hand", cursive,
    "Andale Mono Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

.container-all {
  margin: 0px;
  width: auto;
  backdrop-filter: grayscale(100%);
  margin: 0;


}
.container-section-aboutUs {
  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  

 

  // @media screen and (max-width: 1224px) {
  //   background-position: 5% 59%;

  // }
  // @media screen and (max-width: 1280px) {
  //   background-position: 5% 85%;

  // }
  // @media screen and (max-width: 1400px) {
  //   background-position: 5% 73%;

  // }
  // @media screen and (max-width: 1600px) {
  //   background-position: 5% 59%;

  // }
}
/* code {
  font-family: 'Gregs Other Hand', cursive,'Andale Mono Regular'
    monospace;
} */
.container-section-contact{
  
    color: white;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
  
    position: relative;
    width: auto;
    -webkit-box-shadow: 0px 0px 28px 17px #000000;
  box-shadow: 0px 0px 28px 17px #000000;
  @media (max-width: 560px) {
    height: 80vh;
   }
   @media (max-width: 460px) {
    height: 60vh;
       }
}
.container-section-noticias{
  
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 120vh;
  position: relative;
  width: auto;
  flex-direction: column;
 

}

.container-section-footer{
  height: 20vh;
  
}
